import {EditorSDK} from '@wix/platform-editor-sdk'
import {EVENTS_APP_DEF_ID, DETAILS_PAGE_ID, SCHEDULE_PAGE_ID} from './constants'

export const ensurePagesManaged = async (editorSDK: EditorSDK, appToken, t) => {
  const [{applicationId}, allPages] = await Promise.all([
    editorSDK.tpa.app.getDataByAppDefId(appToken, EVENTS_APP_DEF_ID),
    editorSDK.pages.data.getAll(appToken),
  ])

  const eventsPages = allPages.filter(({tpaApplicationId}) => tpaApplicationId === applicationId)
  const managedPageData = {
    [DETAILS_PAGE_ID]: {
      title: t('pagesPanelEventsDetailsTitle'),
    },
    [SCHEDULE_PAGE_ID]: {
      title: t('pagesPanelEventsScheduleTitle'),
    },
  }
  const managedPages = Object.keys(managedPageData)

  eventsPages.map(({id, type, managingAppDefId: currentManagingAppDefId, tpaPageId}) => {
    if (currentManagingAppDefId !== EVENTS_APP_DEF_ID && managedPages.includes(tpaPageId)) {
      const data = {
        managingAppDefId: EVENTS_APP_DEF_ID,
        title: managedPageData[tpaPageId].title,
      }

      // @ts-expect-error
      editorSDK.pages.data.update(appToken, {pageRef: {id, type}, data})
    }
  })
}
